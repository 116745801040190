<template>
  <section class="fontStandard">
    <!-- User Interface controls -->
    <b-row class="pt-5">
      <b-col md="4" lg="4" class="text-left">
        <h3>
          <b-badge variant="light"
            >{{ totalRows }} entrée{{ totalRows > 1 ? 's' : '' }}
          </b-badge>
        </h3>
      </b-col>
      <b-col md="0" lg="4"></b-col>
      <b-col md="6" lg="4">
        <b-input-group>
          <b-button
            class="mr-1"
            variant="primary"
            @click="addQrCode($event.target)"
          >
            <i class="fas fa-plus"></i> <strong>Ajouter</strong>
          </b-button>
          <b-button @click="$refs.table.refresh()" variant="primary">
            <i class="fas fa-sync"></i>
          </b-button>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Recherche"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>

    <b-table
      show-empty
      small
      responsive
      stacked="lg"
      ref="table"
      :items="myProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      striped
      hover
      foot-clone
      bordered
      borderless
      head-variant="dark"
      table-variant="light"
    >
      <template v-slot:table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>

      <template v-slot:cell(enable)="data">
        <b-link @click="toggle_qr_code(data.item.id, data.item.enable)">
          <b
            :class="[data.item.enable === '1' ? 'text-primary' : 'text-danger']"
            >{{ data.value }}</b
          >
        </b-link>
      </template>

      <template v-slot:cell(url)="data">
        <b-link @click="modifyQrCode(data.item, data.index, $event.target)">
          <!--  -->
          <b>{{
            $tools.formatter_lowercase(
              $tools.showLimitedString(data.value, truncateUrl)
            )
          }}</b>
        </b-link>
      </template>

      <template v-slot:cell(used)="data">
        <b-link @click="modifyQrCode(data.item, data.index, $event.target)">
          <b>{{ data.value }}</b>
        </b-link>
      </template>

      <template v-slot:cell(create_date)="data">
        <b>{{ data.value }}</b>
      </template>
      <template v-slot:cell(update_date)="data">
        <b>{{ data.value }}</b>
      </template>

      <template v-slot:cell(actions)="row">
        <b-container class="text-left" fluid>
          <b-button
            size="sm"
            @click="modifyQrCode(row.item, row.index, $event.target)"
            class="mr-1"
            variant="primary"
          >
            <!-- <i class="fas fa-info-circle"></i>&nbsp; -->
            <strong>Modifier</strong>
          </b-button>
          <b-button
            size="md"
            class="mr-1"
            variant="warning"
            @click="viewQrCode(row.item, row.index, $event.target)"
          >
            <!-- @click="downloadImg" -->
            <i class="fas fa-qrcode"></i>
          </b-button>
          <b-button
            size="md"
            class="mr-1"
            variant="success"
            @click="trafficQrCode(row.item, row.index, $event.target)"
          >
            <i class="fas fa-traffic-light"></i>
          </b-button>
        </b-container>
      </template>
    </b-table>

    <b-row>
      <b-col cols="8">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="left"
          first-number
          last-number
        />
      </b-col>
      <b-col cols="4" class="text-right">
        <b-form-select
          style="width: 5em"
          v-model="perPage"
          id="perPageSelect"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
    </b-row>

    <b-modal
      :id="viewModal.id"
      :title="'Données QR Code n°' + viewModal.item['id']"
      ok-only
      @hide="resetInfoModal"
      centered
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      ok-variant="light"
      header-close-variant="light"
      header-text-variant="light"
      header-border-variant="dark"
      hide-footer
      scrollable
      size="lg"
    >
      <QrCodeView :items="viewModal.item" />
    </b-modal>

    <b-modal
      :id="qrcodeModal.id"
      title="Image QR Code"
      ok-only
      no-stacking
      centered
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      ok-variant="light"
      header-close-variant="light"
      header-text-variant="light"
      header-border-variant="dark"
      hide-footer
      scrollable
      size="md"
    >
      <QrCode
        :value="qrcodeModal.code"
        :color="qrcodeModal.foreground"
        image="png"
        :background="qrcodeModal.background"
        className=" text-center"
        :size="400"
        :quality="qrcodeModal.quality"
      />
      <span>Clic droit enregistrer l'image</span>
    </b-modal>

    <b-modal
      id="add-modal"
      title="Créer un nouveau QR CODE"
      ok-only
      @hide="$refs.table.refresh()"
      no-stacking
      centered
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      ok-variant="light"
      header-close-variant="light"
      header-text-variant="light"
      header-border-variant="dark"
      hide-footer
      scrollable
      size="md"
    >
      <QrCodeAdd />
    </b-modal>

    <b-modal
      :id="trafficModal.id"
      :title="'Trafic Qr Code n°' + trafficModal.id_qr"
      ok-only
      @hide="resetInfoModal"
      centered
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      ok-variant="light"
      header-close-variant="light"
      header-text-variant="light"
      header-border-variant="dark"
      hide-footer
      scrollable
      size="xl"
    >
      <QrCodeTraffic :qr="trafficModal.id_qr" />
    </b-modal>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    QrCodeView: () => import('./QrCodeView'),
    QrCodeAdd: () => import('./QrCodeAdd'),
    QrCode: () => import('@/components/QrCode'),
    QrCodeTraffic: () => import('./QrCodeTraffic'),
  },
  computed: {
    ...mapState(['account', 'events']),
    ...mapGetters(['admin/allow_this_tab', 'admin/allow_this_action']),
    truncateUrl() {
      const width = this.events.width
      if (width <= 992) {
        return 50
      } else if (width <= 1200) {
        return 20
      } else if (width <= 1600) {
        return parseInt(width / 30)
      } else {
        return parseInt(width / 15)
      }
    },
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: false,
          class: 'text-left',
        },

        {
          key: 'create_date',
          label: 'Crée le',
          sortable: true,
          sortDirection: 'desc',
        },

        {
          key: 'url',
          label: 'Url',
          sortable: true,
          class: 'text-left text-truncate',
        },
        {
          key: 'enable',
          label: 'Active',
          formatter: (value, key, item) => {
            return value === '1' ? 'Oui' : 'Non'
          },
          sortable: true,
        },
        {
          key: 'used',
          label: 'Vue(s)',
          sortable: true,
          class: 'text-left',
          sortByFormatted: true,
          filterByFormatted: true,
        },

        {
          key: 'update_date',
          label: 'Mise à jour le',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'actions', label: 'Actions', class: 'text-center' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [1, 7, 15],
      sortBy: 'create_date',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      isBusy: false,
      viewModal: {
        id: 'view-modal',
        item: null,
      },
      trafficModal: {
        id: 'traffic-modal',
        id_qr: -1,
      },
      qrcodeModal: {
        id: 'qrcode-modal',
        code: null,
        foreground: 'black',
        background: 'white',
        quality: 'L',
      },
    }
  },
  created() {
    this.defaultInfoModal()
  },
  mounted() {
    this.currentPage = 1
  },
  beforeDestroy() {
    this.items = this.fields = this.pageOptions = undefined
  },
  methods: {
    modifyQrCode(item, index, button) {
      this.viewModal.item = item
      this.$root.$emit('bv::show::modal', this.viewModal.id, button)
    },
    trafficQrCode(item, index, button) {
      this.trafficModal.id_qr = parseInt(item.id)
      this.$root.$emit('bv::show::modal', this.trafficModal.id, button)
    },
    viewQrCode(item, index, button) {
      this.qrcodeModal.code = item['code']
      this.qrcodeModal.foreground = item['foreground']
      this.qrcodeModal.background = item['background']
      this.qrcodeModal.quality = item['quality']
      this.$root.$emit('bv::show::modal', this.qrcodeModal.id, button)
    },
    addQrCode(button) {
      this.$root.$emit('bv::show::modal', 'add-modal', button)
    },
    defaultInfoModal() {
      this.viewModal.item = {
        id: -1,
        enable: false,
        code: null,
        url: null,
        used: 0,
        create_date: null,
        update_date: null,
      }
    },
    resetInfoModal() {
      this.defaultInfoModal()
      this.$refs.table.refresh()
    },
    async myProvider(ctx) {
      this.isBusy = true
      if (ctx && ctx.filter && ctx.filter.length > 30) {
        this.totalRows = 0
        this.isBusy = false
        return []
      }

      this.$store.dispatch('axios/http_reading', true)

      const vm = this.$root
      await vm.handleGetRequest('api/token/get', false)
      const data = await vm.handleGetRequest('qr/codes/list', true, {
        currentPage: ctx.currentPage,
        perPage: ctx.perPage,
        filter: ctx.filter,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
      })

      const tbl = data && data['qr_codes_list'] ? data['qr_codes_list'] : null

      this.currentPage =
        tbl && tbl.currentPage ? tbl.currentPage : this.currentPage
      this.totalRows = tbl && tbl.totalRows ? tbl.totalRows : 0

      if (tbl && tbl.items) {
        this.isBusy = false
        return JSON.parse(tbl.items)
      } else {
        this.currentPage = 1
        this.isBusy = false
        return []
      }
    },

    async toggle_qr_code(id_qrcode = -1, enable = '0') {
      const supervisor_enable = enable === '1' ? '0' : '1'

      const uri =
        supervisor_enable === '1' ? 'qr/codes/enable' : 'qr/codes/disable'

      await this.$root.axios('put', uri, {
        id: id_qrcode,
      })

      const axios = this.account.axios_data
      if (axios && axios.api_response === 'successfull_change') {
        this.$refs.table.refresh()
      }
    },
  },
}
</script>

<style scoped>
.table_color_light {
  background: red;
}
</style>
